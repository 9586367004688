<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/serialno`"
                :formOptions="formOptions"
                title="Serial No"
                :columns="columns"
                routerpath="/setup/addserialno"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
import moment from 'moment'
import axios from '@/components/axios'
const baseApi = process.env.VUE_APP_APIENDPOINT

export default {
  components: {
    GoodTableColumnSearch
  },
  data () {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: 'ID',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search ID'
          },
          hidden: true
        },
        {
          label: 'Company',
          field: 'company',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Company ID'
          }
        },
        {
          label: 'Finance Year',
          field: 'financeyear',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Finance Year'
          }
        },
        {
          label: 'Prefix',
          field: 'prefix',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Prefix'
          }
        },
        {
          label: 'Suffix',
          field: 'suffix',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Suffix'
          }
        },
        {
          label: 'No Of Digit',
          field: 'noofdigit',
          filterOptions: {
            enabled: true,
            placeholder: 'Search No Of Digit'
          }
        },
        {
          label: 'Voucher Type',
          field: 'vouchertype',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Voucher Type'
          }
        },
        {
          label: 'Enter By',
          field: 'enterby',
          type: 'dropdown',
          url: 'getUsers',
          responseValue: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },
        {
          label: 'Enter Date',
          field: 'created_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date'
          }
        },
        {
          label: 'Modified By',
          field: 'modifiedby',
          type: 'dropdown',
          url: 'getUsers',
          responseValue: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },
        {
          label: 'Modified Date',
          field: 'updated_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date'
          }
        },
        {
          label: 'Action',
          field: 'action'
        }
      ],
      formOptions: {
        formTitle: `${
          this.$route.params.id ? 'Edit Serial No' : 'Add Serial No'
        }`,
        submitRouterPath: '/setup/serial-no',
        usersDropdown: true,
        method: 'post',
        action: 'add',
        url: `${baseApi}/serialno`,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Company Name',
            name: 'name',
            value: '',
            type: 'dropdown',
            url: 'company',
            options: [],
            placeholder: 'Enter Name',
            class: 'col-md-12',
            required: true
          },
          {
            label: 'Branch Name',
            name: 'branchname',
            value: '',
            type: 'dropdown',
            url: 'branch',
            options: [],
            placeholder: 'None',
            class: 'col-md-12',
            required: true
          },
          {
            label: 'F/Y',
            name: 'fy',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            dropdownname: 'fy',
            // url:"",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: ['2021-2022', '2022-2023']
          },

          {
            label: 'Prefix',
            name: 'prefix',
            value: '',
            type: 'text',
            placeholder: 'Enter Prefix',
            class: 'col-md-12',
            required: true
          },
          {
            label: 'Suffix',
            name: 'suffix',
            value: '',
            type: 'text',
            placeholder: 'Enter Suffix',
            class: 'col-md-12',
            required: true
          },
          {
            label: 'No Of Digit',
            name: 'noofdigit',
            value: '',
            type: 'number',
            placeholder: 'Enter No Of Digit',
            class: 'col-md-12',
            required: true
          },
          {
            label: 'Voucher Type',
            name: 'vouchertype',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            dropdownname: 'vouchertype',
            // url:"",
            required: true,
            options: ['Sales Challan', 'Tax Invoice']
          }
        ]
      }
    }
  },
  beforeMount () {
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      this.$router.push('/')
    }
    this.getDropdowns()
  },
  methods: {
    getDropdowns () {
      const accessToken = localStorage.getItem('accessToken')
      const baseApi = process.env.VUE_APP_APIENDPOINT
      this.formOptions.inputFields.map(async (item) => {
        if (item.type == 'dropdown' && item.url) {
          const requestOptionsUsers = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            url: `${baseApi}/${item.url}`
          }

          await axios(requestOptionsUsers)
            .then((response) => {
              response.data.data.data
                ? (item.options = response.data.data.data)
                : (item.options = response.data.data)
            })
            .catch((error) => console.log(error, 'error'))
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
